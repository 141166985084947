<template>
  <LayoutFilter
    :toggleCollapseEnable="false"
    :onFilter="ok"
    :onReset="resetForm"
    :onExport="onExport"
  >
    <el-form
      :model="filterForm"
      :rules="rules"
      ref="filterFilterForm"
      label-width="75px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <div class="filter-item">
        <!--        当一个 form 元素中只有一个输入框时，在该输入框中按下回车应提交该表单。
        如果希望阻止这一默认行为，可以 在 标签上添加 @submit.native.prevent。-->
        <!-- <el-form-item label="老师ID：" prop="id">
          <el-input
            @keyup.enter.native="ok"
            v-model="form.id"
            placeholder="请输入"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="老师名称：" prop="keyword">
          <el-input
            @keyup.enter.native="ok"
            v-model="form.keyword"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { exportFile } from '@/modules/college/api/list.js'
export default {
  name: 'ListFilter',
  components: { DatePeriodPicker, LayoutFilter },
  props: {
    filterForm: {
      type: Object,
    },
    //导出要用的数据
    idList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rules: {},
      form: null,
    }
  },
  watch: {
    filterForm: {
      deep: true,
      handler(val) {
        this.form = val
      },
    },
  },
  created() {
    this.form = this.filterForm
  },
  methods: {
    //导出
    onExport() {
      return exportFile({
        export_ids: this.idList,
        ...this.form,
      }).catch(() => {})
    },
    //重置表单
    resetForm() {
      this.$refs['filterFilterForm'].resetFields()
      this.ok()
    },
    //筛选
    ok() {
      this.$emit('update', this.form)
    },
  },
}
</script>

<style scoped></style>
