<template>
  <list-layout
    ref="listLayout"
    :filter-form="filterForm"
    :thead="thead"
    :tabArr="tabArr"
    :on-fetch="getList"
    :on-edit="handleAdd"
    :on-delete="onDelete"
    :on-put-back="onPutBack"
    :on-update="onUpdate"
  >
    <template #top>
      <el-button
        class="top-btn"
        size="small"
        type="primary"
        @click="handleAdd()"
        >添加老师</el-button
      >
    </template>
    <template #filter="{ idList }">
      <list-filter :idList="idList" :filter-form="filterForm" @update="ok" />
    </template>

    <template #item_courses_count="{row}">
      <el-button @click="toDetailList(row, 'course')" type="text" v-if="row.courses_count">{{row.courses_count}}</el-button>
      <span v-else>{{row.courses_count}}</span>
    </template>

    <template #item_audios_count="{row}">
      <el-button @click="toDetailList(row, 'audio')" type="text" v-if="row.audios_count">{{row.audios_count}}</el-button>
      <span v-else>{{row.audios_count}}</span>
    </template>
  </list-layout>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout'
import {
  getDetail,
  getList,
  saveDetail,
  softDelete,
  Delete,
  putBack,
  saveSort,
} from '@/modules/college/api/list'
import ListFilter from '@/modules/college/components/ListFilter'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'

const DIALOG_MODEL = {
  id: '',
  avatar: '',
  nickname: '',
  phone: '',
}

export default {
  name: 'list',
  components: { SingleMediaWall, ListLayout, ListFilter },
  data() {
    return {
      // tab配置
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      filterForm: {
        page: 1,
        page_size: 15,
        is_desc: 1,
        order_by: 'id',
        tab: 'normal',
      },
      thead: [
        { type: 'selection', width: 80 },
        { label: '老师ID', prop: 'id', width: 140 },
        { label: '排序', prop: 'sort', minWidth: 100 },
        { label: '老师名称', prop: 'name', minWidth: 100 },
        { label: '老师头像', prop: 'avatar', minWidth: 150 },
        { label: '相关课程', prop: 'courses_count', minWidth: 120, type: 'slot' },
        { label: '相关听书', prop: 'audios_count', minWidth: 120, type: 'slot' },
        // { label: '老师评价', prop: 'courses_count', minWidth: 200 },
        {
          label: '操作',
          type: 'operation',
          width: 100,
          operations: [
            { command: 'edit', text: '编辑' },
            { command: 'delete', text: '删除' },
          ],
        },
      ],
      dialog: {
        show: false,
        data: JSON.parse(JSON.stringify(DIALOG_MODEL)),
        editLoading: false,
        loading: false,
      },
    }
  },
  methods: {
    toDetailList(row, type = 'course') {
      this.$router.push({
        name: {
          // course: 'ClassroomCourseManageIndex',
          course: 'CollegeTeacherCourseList',
          // audio: 'CollegeAudioList',
          audio: 'CollegeTeacherAudioList'
        }[type],
        params: {},
        query: {
          teacher_id: row.id,
          teacher_name: encodeURIComponent(row.name)
        }
      })
    },
    onUpdate(data) {
      return saveSort(data)
    },
    /**
     * 列表
     * @param params
     * @returns {AxiosPromise}
     */
    getList(params) {
      return getList(params)
    },
    /**
     * 新增、编辑
     * @param obj
     */
    handleAdd(obj = {}) {
      this.$router.push({
        name: 'AddCollegeTeacher',
        params: {
          id: obj.id || 0,
        },
      })
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.$refs.listLayout.getList(this.filterForm)
    },
    //  还原
    onPutBack(idList) {
      return putBack({ id: idList })
    },
    /**
     * 弹窗
     */
    confirm() {
      this.dialog.loading = true
      saveDetail(this.dialog.data)
        .then((res) => {
          this.$message.success(res.msg)
          this.close()
          this.$refs.listLayout.getList(this.filterForm)
        })
        .catch((err) => {})
        .finally(() => {
          this.dialog.loading = false
        })
    },
    // 数据删除
    onDelete(idList, forever = false) {
      return forever ? Delete({ id: idList }) : softDelete({ id: idList })
    },
    close() {
      this.dialog.show = false
    },
  },
}
</script>

<style scoped></style>
